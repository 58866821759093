#map {
    width: 100%;
    position: relative;
}

.search {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 400px;
    z-index: 10;
  }
  
  #searchbar {
    margin-left: 10px;
    width: 75%;
    max-width: 800px;
    border-radius: 0.25rem;
  }

  .locate {
    position: absolute;
    top: 5px;
    right: 1rem;
    background: none;
    border: none;
    z-index: 10;
  }
  .locate img {
    width: 50px;
    height: 50px;
  }

  .gem-picture{
    height: 50px;
  }

  ul{
    background: rgba(25,25,25,0.5);
    color: white;
    border-radius: 0.25rem;
  }

