.user {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 56px);
    background-image: url('https://hidden-gems-application.s3-us-west-2.amazonaws.com/backgrounds/user.webp');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    background: rgba(255,255,255,0.6);
    padding-bottom: 1px;
    border-radius: 0.25rem;
}

#update-button {
    margin-top: 5px;
    border: none;
    background: none;
    text-decoration: underline;
    color: black;
}

.single-pin {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.single-pin Button {
    margin-top: 5px;
}

#user-card {
    background: rgba(255,255,255,0.9);
}

#user-card Button {
    color: black;
}

#user-container {
    padding-top: 10px;
}

#profile-pic{
    border-radius: 0.25rem;
    max-height: 100px;
    max-width: 100px;
}

#gem-pics {
    border-radius: 0.25rem;
    max-height: 300px;
    max-width: 80%;
}

.update-form-buttons {
    margin-top: 10px;
    margin-right: 10px;
}