#pin-form-page {
    width: 100vw;
    height: calc(100vh - 56px);
    background-image: url('https://hidden-gems-application.s3-us-west-2.amazonaws.com/backgrounds/Gem.webp');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

#pin-form-container {
    display: flex;
    justify-content: center;
}

#new-card {
    padding: 10px;
    margin-top: 100px;
    width: 100%;
    background: rgba(25,25,25,0.5);
    color: white;
}

#form-buttons {
    display: flex;
    justify-content: space-between;
}

