.widget-svg{
    width: 20px;
}

#gem-page {
    width: 100%;
    min-height: calc(100vh - 56px);
    background-image: url('https://hidden-gems-application.s3-us-west-2.amazonaws.com/backgrounds/Gem.webp');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

#gem-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#comment-form {
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;
    background: rgba(25,25,25,0.5);
    color: white;
}

#gem-card {
    min-height: 400px;
    max-width: 400px;
    margin-top: 50px;
    background: rgba(25,25,25,0.5);
    color: white;
    padding: 10px;
}

#gem-img {
    max-height: 500px;
    max-width: 500px;
}

#pin-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.star-span {
    margin-right: 10px;
    min-width: 108px;
    padding-bottom: 4px;
}

#form-label {
    display: flex;
    justify-content: space-between;
}

.comment-img{
    margin-top: 3px;
    margin-left: 3px;
    max-height:40px;
    max-width: 40px;
    border-radius: 50px;
}

.comment-display{
    padding: 5px;
    width: 100%;
    max-width: 400px;
    margin-top: 10px;
    background: rgba(25,25,25,0.5);
    color: white;
}

.comment-stars{

}

.comment-header{
    display: flex;
    justify-content: space-between;
}

#commentContainer{
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    padding-bottom: 40px;
}